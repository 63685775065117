import * as React from 'react';
import LoadingSpinner from 'mz-ui-kit/core/LoadingSpinner';
import { PageLoadingWrapper, LoadingWrapper, LoadingText } from './elements';

const PageLoadingSpinner = ({ message }) => (
  <PageLoadingWrapper>
    <LoadingWrapper>
      <LoadingSpinner size="large" />
      {!!message && <LoadingText>{message}</LoadingText>}
    </LoadingWrapper>
  </PageLoadingWrapper>
);

export default PageLoadingSpinner;
