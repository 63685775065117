import { createReducer } from 'app/utils/redux';
import { SET_RESERVATION, TOGGLE_MODAL, CP_SET_LOADING } from 'app/constants';
import ChangeReservationPage from 'blocks/change-booking/ChangeReservationPage/lazy';

const initialState = {
  loading: true,
  departing: null,
  returning: null,
  changeReservation: ChangeReservationPage.createModel({}),
};

const actionHandlers = {
  [SET_RESERVATION]: (state, { departing, returning }) => ({
    ...state,
    departing,
    returning,
  }),
  [TOGGLE_MODAL]: (state) => ({
    ...state,
    modal: !state.modal,
  }),
  [CP_SET_LOADING]: (state, loading) => ({
    ...state,
    loading,
  }),
};

export default createReducer(initialState, actionHandlers);
