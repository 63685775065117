// @flow
import * as React from 'react';
import PageLoadingSpinner from 'app/components/base/PageLoadingSpinner';

/**
 * This view showed while the code chunk with the page
 * is loading.
 * @return {React.Element}
 */
const ViewPlaceholder = () => <PageLoadingSpinner />;

export default ViewPlaceholder;
